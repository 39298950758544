<nav class="navbar top-navbar navbar-expand-lg navbar-light  w-100 ">
    <div class="logoContainer">
        <a href="#" class="nabar-brand logo "><img src="/assets/images/img/wellcarelogored.png" alt="" class="img-fluid"></a>
    </div>

    <button class="navbar-toggler navbar-toggler-right " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="alignTogler">
            <div class="text">Call Us</div>

            <div>
                <button class="butn">
                    <div class="callusmain">
                    <div class="callusimg"><img class="calusimage" src="/assets/images/img/callus_icon.png" alt=""></div>
                  <div class="mob">
                    <div class="callusnum" > <a  class="callusnum" href="tel:+443337729507">0333 772 9507</a></div> 
                  <div class="callusnum" > <a  class="callusnum" href="tel:+447742426100">0751 069 8999</a></div> 
                  <!-- <div class="callusnum" > <a  class="callusnum" href="tel:+447742426100">0774 242 6100</a></div>  -->
                  <!-- <div class="callusnum">03337729507/0774 242 6100</div>  -->
                </div>
                </div>
            </button></div>
        </div>

        <div class="navbar-nav  top-nav-right collapse-right ">

            <a class="nav-link home active" routerLink="/home">HOME</a>
            <a class="nav-link about" routerLink="/about">ABOUT US</a>
            <a class="nav-link sell" routerLink="/services">OUR SERVICES</a>
            <a class="nav-link contact" routerLink="/contact">CONTACT US</a>
            <!-- <a class="nav-link register" target="_blank" href="https://www.wellcareltd.uk/register.aspx">REGISTER</a> -->

        </div>
    </div>
</nav>