<footer class="page-footer col-12">
  <!-- Footer Links -->
  <div class="fdivs">
    <!-- Footer links -->
    <div class="row">
      <!-- Grid column -->
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-3 segment1">
        <!-- <div class="container"> -->
        <div class="logoContainer">
          <a routerLink="/home"
            ><img
              class="footerlogo"
              src="/assets/images/img/wellcarelogored.png"
              alt="WellCare"
          /></a>
        </div>
        <div class="contentbody footercaption">
          <p class="footercaption">
            Challenges are a part of life and <br />we provide a way to overcome
            them to make life better
          </p>
          <!-- <img class="qrcodesize" src="/assets/images/img/Well_Care_Ltd.png" alt=""> -->
        </div>
      </div>
      <!-- Grid column -->

      <hr class="infoSection w-100 clearfix d-lg-none" />

      <!-- Grid column -->
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-3 segment2">
        <h6>Quick Links</h6>
        <p class="information">
          <a class="a-quick" routerLink="/home">HOME</a>
        </p>
        <p class="information">
          <a class="a-quick" routerLink="/about">ABOUT US</a>
        </p>
        <p class="information">
          <a class="a-quick" routerLink="/services">OUR SERVICES</a>
        </p>
        <p class="information">
          <a class="a-quick" routerLink="/contact">CONTACT US</a>
        </p>
        <p class="information">
          <a
            class="a-quick"
            href="https://www.wellcareltd.uk/register.aspx"
            target="_blank"
            >REGISTER</a
          >
        </p>
      </div>
      <!-- Grid column -->

      <hr class="w-100 clearfix d-lg-none" />

      <!-- Grid column -->
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-3 segment3 comonline">
        <h6 class="comonline">Contact Info</h6>
        <p class="iconfooter">
          <img
            class="logosize"
            src="/assets/images/img/phone-red-icon.png"
            alt=""
          />

          <a class="routerwidthmax" href="tel:+443337729507">03337729507,</a>

          <a class="routerwidthmax" href="tel:+447510698999">07510698999</a>
        </p>

        <p class="iconfooter">
          <img
            class="logosize"
            src="/assets/images/img/mail-red-icon.png"
            alt=""
          />
          <a class="routerwidthmax" href="mailto:info@wellcareltd.co.uk"
            >info@wellcareltd.co.uk</a
          >
        </p>
        <p class="iconfooter">
          <img
            class="logosize"
            src="/assets/images/img/location-red-icon.png"
            alt=""
          />
          <a class="address" routerLink="/home">
            Well care Ltd <br />
            County house <br />
            St Marys street <br />
            Worcester  <br />
            WR1 1HB<br />
            </a
          >
          <!-- <a class="address " routerLink="/home">
                        WELL CARE LTD, 12 D<br>
                SHRUB HILL INDUSTRIAL ESTATE <br>
                WORCESTER, WR4 9EL.</a> -->
        </p>
      </div>

      <!-- Grid column -->
      <hr class="w-100 clearfix d-lg-none" />

      <!-- Grid column Contact us -->

      <div
        class="col-md-12 col-sm-12 col-xs-12 col-lg-3 segment4 comonlineInsta"
      >
        <h6 class="comonline">Social Media</h6>
        <!-- <div class="last-col "> -->
        <p class="iconfooter">
          <img
            class="logosize"
            src="/assets/images/img/insta-red-icon.png"
            alt=""
          />
          <a
            href="https://instagram.com/wellcare_official?igshid=efjs5ag0wx0v"
            target="_blank"
            >wellcare_official</a
          >
        </p>
        <p class="iconfooter">
          <img
            class="logosize"
            src="/assets/images/img/fb-red-icon.png"
            alt=""
          />
          <a
            href="https://www.facebook.com/Well-care-103900324936643"
            target="_blank"
            >wellcareltdofficial
          </a>
        </p>
        <p class="iconfooter">
          <img
            class="logosize"
            src="/assets/images/img/twitter-red-icon.png"
            alt=""
          />
          <a href="https://twitter.com/wellcareltd" target="_blank"
            >@wellcareltd</a
          >
        </p>
        <p class="iconfooter">
          <img
            class="logosize"
            src="/assets/images/img/whatsapp-red-icon.png"
            alt=""
          />
          <a
            href="https://api.whatsapp.com/send?phone=4407510698999"
            target="_blank"
            >+44 0751 0698999</a
          >
        </p>

        <!-- </div> -->

        <!-- Grid column -->
      </div>
    </div>
    <!-- Footer links -->

    <div class="row d-flex rightsSec align-items-center">
      <!-- Grid column -->
      <div class="fbottom">
        <!-- Rights Section -->
        <div class="row d-flex rightsSec align-items-center">
          <div class="fbottom col-md-6 col-lg-6">
            <div class="container frightsec text-center text-md-left">
              © {{ currentYear }} :
              <a href="http://www.wellcareltd.co.uk/home">
                wellcareltd.co.uk
              </a>
              All Rights Reserved
            </div>
          </div>
          <div class="copyright1 col-md-6 col-lg-6">
            <div class="fcopyrightsection">
              Powered By:
              <a href="https://pennonn.com/" target="_blank">Pennonn LLC</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
